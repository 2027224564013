import React from 'react';

const ForumFrame = ({ content }) => (
  <iframe
    src={`https://gamily-ao.com/gamily-forum/${
      content.url || 'index.php'
    }`}
    title="Gamily Forum"
    className="forum-iframe"
  />
);

export default ForumFrame;
