import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import StatsIcon from '../../assets/images/gamily_icons/stats.png';

const VideoCardTwo = ({ content }) => (
  <section className="video-card-two">
    <Container>
      <div className="inner-container" style={{ backgroundColor: '#C4C4C4' }}>
        <Row className="align-items-center">
          <Col lg={3}>
            <div
              className="video-card-two__box"
              style={{
                backgroundColor: content.icon_colour.color,
                borderRadius: '50%',
                height: '100%',
                objectFit: 'contain',
                padding: '32px',
              }}
            >
              <img
                src={StatsIcon}
                alt=""
                style={{ width: '100px', height: '100px' }}
              />
            </div>
          </Col>
          <Col lg={4}>
            <h3>{content.title}</h3>
          </Col>
          <Col lg={5}>
            <p>{content.copy}</p>
          </Col>
        </Row>
      </div>
    </Container>
  </section>
);

export default VideoCardTwo;
