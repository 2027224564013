import React from 'react';
import { Link } from 'gatsby';
import { Container } from 'react-bootstrap';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import InfoIcon from '../../assets/images/gamily_icons/information.png';
import DiscussionIcon from '../../assets/images/gamily_icons/discussion.png';
import BlogIcon from '../../assets/images/gamily_icons/blog-icon.png';

const ServiceTwo = ({ content }) => {
  SwiperCore.use([Pagination]);

  const SERVICE_TWO_DATA = [
    {
      extraClassName: 'background-base',
      title: content.title_a,
      text: content.subtitle_a,
      link: content.link_a,
      icon: InfoIcon,
      colour: content.colour_a.color,
    },
    {
      extraClassName: 'background-base',
      title: content.title_b,
      text: content.subtitle_b,
      link: content.link_b,
      icon: DiscussionIcon,
      colour: content.colour_b.color,
    },
    {
      extraClassName: 'background-base',
      title: content.title_c,
      text: content.subtitle_c,
      link: content.link_c,
      icon: BlogIcon,
      colour: content.colour_c.color,
    },
  ];

  const sliderOptions = {
    slidesPerView: 3,
    spaceBetween: 30,
    pagination: {
      el: '.swiper-pagination',
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      375: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      575: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      991: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1199: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
  };
  return (
    <section className="service-two">
      <Container>
        <Swiper {...sliderOptions}>
          {SERVICE_TWO_DATA.map(
            ({ extraClassName, title, text, link, icon, colour }, index) => (
              <>
                <SwiperSlide key={`service-two-key-${index}`}>
                  <div className={`service-two__box ${extraClassName}`}>
                    <div className="service-two__box-inner">
                      <img src={icon} width="60" alt="" />
                      <p>{text}</p>
                      <h3>
                        <Link to={link.cached_url}>{title}</Link>
                      </h3>
                      <Link
                        className="service-two__box-link"
                        to={link.cached_url}
                        style={{ backgroundColor: colour }}
                      >
                        <i className="far fa-angle-right" />
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
                <div className="swiper-pagination" />
              </>
            )
          )}
        </Swiper>
      </Container>
    </section>
  );
};

export default ServiceTwo;
