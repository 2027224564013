import React from 'react';
import { Link } from 'gatsby';
import { Container } from 'react-bootstrap';

const VideoCard = ({ content }) => (
  <section className="video-card">
    <Container className="text-center pt-120 pb-120">
      <p>{content.subtitle}</p>
      <h3>{content.title}</h3>
      {content.link && (
        <div className="video-card__btn-block">
          <Link
            to={content.link.cached_url}
            className="thm-btn dynamic-radius"
            style={{ backgroundColor: content.link_colour.color }}
          >
            {content.link_label}
          </Link>
        </div>
      )}
    </Container>
  </section>
);

export default VideoCard;
