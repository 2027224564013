import React, { useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import SbRichtext from '../../helpers/SbRichtext';

const Step = ({ index, copy }) => {
  const [more, setMore] = useState(false);

  const dirty = SbRichtext(copy);
  const clean = sanitizeHtml(dirty, {
    allowedTags: ['p', 'a'],
    allowedAttributes: {
      a: ['href', 'target'],
    },
  });

  return (
    <div className="step">
      <h5>Step {index}</h5>
      <p
        className={more ? 'show-all' : 'step-fade'}
        dangerouslySetInnerHTML={{ __html: clean }}
      />

      <button type="button" onClick={() => setMore(!more)}>
        Read {more ? 'Less' : 'More'}
      </button>
    </div>
  );
};

export default Step;
