import ComponentNotFound from './ComponentNotFound';

import HomeTwo from '../components/sections/home';

import MainSliderTwo from '../components/slider/main-slider-2';
import AboutOne from '../components/about/about-one';
import ServiceTwo from '../components/services/service-two';
import HeaderTwo from '../components/header/header-two';
import VideoCardTwo from '../components/videos/video-card-two';
import VideoCard from '../components/videos/video-card';
import CausesHome from '../components/causes/causes-home';
import Steps from '../components/steps/steps';
import BlogDetails from '../components/blog-details';
import Support from '../components/causes/support';
import ForumFrame from '../components/forum-frame';
import ContactFormOne from '../components/contact/contact-form-one';
import CopyBlock from '../components/CopyBlock';

// ============================================================================

const ComponentList = {
  home: HomeTwo,
  how_to: HomeTwo,
  blog: HomeTwo,
  support: HomeTwo,
  forum: HomeTwo,
  post: BlogDetails,
  contact: HomeTwo,
  main_slider_two: MainSliderTwo,
  about_one: AboutOne,
  service_two: ServiceTwo,
  header_two: HeaderTwo,
  video_card: VideoCard,
  video_card_two: VideoCardTwo,
  news: CausesHome,
  steps: Steps,
  support_links: Support,
  forum_frame: ForumFrame,
  contact_form: ContactFormOne,
  copy_block: CopyBlock,
};

// ============================================================================

const Components = (type) => {
  if (typeof ComponentList[type] === 'undefined') {
    return ComponentNotFound;
  }
  return ComponentList[type];
};

// ============================================================================

export default Components;
