import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import StoryblokClient from 'storyblok-js-client';

const Storyblok = new StoryblokClient({
  accessToken: 'MO4csJcrGTwFRhDN3Tpr5gtt',
});

const CopyBlock = ({ content }) => {
  const data = Storyblok.richTextResolver.render(content.copy);

  return (
    <Container>
      <Row style={{ margin: '48px 0' }}>
        <Col md={12} lg={{ span: 8, offset: 2 }}>
          <div
            className="copyBlock"
            dangerouslySetInnerHTML={{ __html: data }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default CopyBlock;
