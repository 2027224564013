import React, { useState, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import BlockTitle from '../block-title';

const ContactFormOne = ({ content }) => {
  const formRef = useRef(null);

  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);

  async function handleSend(e) {
    e.preventDefault();

    if (!formRef.current.checkValidity()) {
      formRef.current.childNodes.forEach((el) => {
        if (el.required && el.value === '') {
          el.classList.add('invalid');
        }
      });
    }

    if (formRef.current.checkValidity()) {
      setError(false);

      const formElements = [...formRef.current.elements];
      const validElements = formElements
        .filter((el) => !!el.value)
        .map(
          (el) =>
            `${encodeURIComponent(el.name)}=${encodeURIComponent(el.value)}`
        )
        .join('&');

      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: validElements,
      })
        .then(() => {
          setSent(true);
        })
        .catch((err) => {
          setError(err);
        });
    } else {
      setError('Please fill out all required fields correctly');
    }
  }

  return (
    <section className="contact-page pt-120 pb-80">
      <Container>
        <Row>
          <Col lg={5}>
            <div className="contact-page__content mb-40">
              <BlockTitle title={content.title} tagLine={content.subtitle} />
              <p className="block-text mb-30 pr-10">{content.copy}</p>
              <div className="footer-social black-hover">
                {content.social_links.map((node) => (
                  <a
                    href={node.url && node.url.cached_url}
                    aria-label="twitter"
                  >
                    <i className={`fab fa-${node.icon}`} />
                  </a>
                ))}
              </div>
            </div>
          </Col>
          <Col lg={7}>
            <form
              className="contact-form-validated contact-page__form form-one mb-40"
              name="contact_form"
              ref={formRef}
              data-netlify="true"
            >
              <input type="hidden" name="form-name" value="contact_form" />
              <div className="form-group">
                <div className="form-control">
                  <label htmlFor="name" className="sr-only">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Your Name*"
                    required
                  />
                </div>
                <div className="form-control">
                  <label htmlFor="email" className="sr-only">
                    email
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Email Address*"
                    required
                  />
                </div>
                <div className="form-control">
                  <label htmlFor="phone" className="sr-only">
                    phone
                  </label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="Phone Number"
                  />
                </div>
                <div className="form-control">
                  <label htmlFor="subject" className="sr-only">
                    subject
                  </label>
                  <input
                    type="text"
                    name="subject"
                    id="subject"
                    placeholder="Subject"
                  />
                </div>
                <div className="form-control form-control-full">
                  <label htmlFor="message" className="sr-only">
                    message
                  </label>
                  <textarea
                    name="message"
                    placeholder="Write a Message*"
                    id="message"
                    required
                  />
                </div>
                {!sent && (
                  <div className="form-control form-control-full">
                    <button
                      type="submit"
                      className="thm-btn "
                      style={{ backgroundColor: '#578FB2', color: '#000' }}
                      onClick={(e) => handleSend(e)}
                    >
                      Submit Message
                    </button>
                  </div>
                )}
                <div className="form-control form-control-full">
                  {error && <p>{error}</p>}
                  {sent && <p>Thanks &mdash; help is on the way!</p>}
                </div>
              </div>
            </form>
            <div className="result" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactFormOne;
