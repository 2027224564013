import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const SupportPagePagination = () => {
  const [activeUrl, setActiveUrl] = useState(false);

  useEffect(() => {
    if (window !== undefined) {
      setActiveUrl(window.location.pathname);
    }
  }, []);

  const LinkItem = ({ link, icon }) => (
    <Link
      to={link}
      className="support-pagination-link"
      style={{
        backgroundColor: link.match(activeUrl) ? '#c4c4c4' : '#958FA3',
        margin: '0px 10px',
      }}
    >
      <i className={icon} />
    </Link>
  );

  return (
    <ul className="list-unstyled post-pagination d-flex justify-content-center align-items-center">
      <li>
        <LinkItem link="/support-affected-others/" icon="far fa-angle-left" />
      </li>
      <li>
        <LinkItem link="/support-loved-ones/" icon="far fa-angle-right" />
      </li>
    </ul>
  );
};

export default SupportPagePagination;

SupportPagePagination.propTypes = {
  link: PropTypes.string,
  icon: PropTypes.string,
};
