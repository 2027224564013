import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useStaticQuery, graphql } from 'gatsby';
import BlogCard from '../blog/blog-card';

const CausesHome = ({ content }) => {
  const data = useStaticQuery(graphql`
    query HomeBlogQuery {
      allStoryblokEntry(filter: { parent_id: { eq: 28905705 } }, limit: 3) {
        nodes {
          full_slug
          field_image_string
          field_intro_string
          field_title_string
          field_author_string
          published_at(formatString: "DD MMM")
        }
      }

      storyblokEntry(slug: { eq: "blog" }) {
        field_blog_enabled_boolean
      }
    }
  `);
  const BLOG_ACTIVE = data.storyblokEntry.field_blog_enabled_boolean;

  return (
    <section className="causes-page causes-home pt-120 pb-120">
      <Container>
        <Row className=" align-items-start align-items-md-center flex-column flex-md-row">
          <Col lg={5}>
            <div className="block-title">
              <p>{content.subtitle}</p>
              <h3>{content.title}</h3>
            </div>
          </Col>
          <Col lg={{ span: 5, offset: 2 }} className=" d-flex">
            <div className="my-auto">
              <p className="block-text pr-10 mb-0">{content.copy}</p>
            </div>
          </Col>
        </Row>

        {BLOG_ACTIVE && (
          <div className="blogWrapper mt-60">
            {data.allStoryblokEntry.nodes.map(
              (
                {
                  full_slug,
                  field_image_string,
                  field_intro_string,
                  field_title_string,
                  field_author_string,
                  published_at,
                },
                index
              ) => (
                <BlogCard
                  key={index}
                  image={field_image_string}
                  title={field_title_string}
                  date={published_at}
                  text={field_intro_string}
                  link={`/${full_slug}`}
                  author={field_author_string}
                />
              )
            )}
          </div>
        )}
      </Container>
    </section>
  );
};

export default CausesHome;
