import React from 'react';

import StoryblokComponents from '../../storyblok/components';
import Layout from '../layout';
import BlogPage from '../blog/blog-page';

const HomeTwo = ({ story, slug }) => {
  if (!story) return null;

  const { title, body } = story;

  const pageContent =
    body &&
    body.map((node) =>
      React.createElement(StoryblokComponents(node.component), {
        key: node._uid,
        content: node,
      })
    );

  if (!pageContent) {
    return (
      <div>
        <h1>Add some content to get started...</h1>
      </div>
    );
  }

  return (
    <Layout pageTitle={title}>
      {pageContent}
      {slug === 'blog' && <BlogPage />}
    </Layout>
  );
};

export default HomeTwo;
