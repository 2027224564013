import React from 'react';

const BlockTitle = ({ tagLine, title }) => (
  <div className="block-title">
    <p>{tagLine}</p>
    <h3>{title}</h3>
  </div>
);

export default BlockTitle;
