import React from 'react';
import { Container } from 'react-bootstrap';
import SupportPagePagination from '../supportPagePagination';

const CausesPage = ({ content }) => (
  <section className="causes-page pt-120 pb-120">
    <Container>
      <div className="causes-col__3">
        {content.links.map(
          ({ copy, link, subtitle, thumbnail, title }, index) => (
            <div
              className="cause-card support-card"
              key={`cause-card-key-${index}`}
            >
              <a href={link.cached_url} target="_blank" rel="noreferrer">
                <div className="cause-card__inner">
                  <div className="cause-card__image">
                    <img src={thumbnail.filename} alt="" />
                  </div>
                  <div className="cause-card__content">
                    <h3>{subtitle} </h3>
                    <h4 style={{ textTransform: 'uppercase' }}>{title}</h4>
                    <p>{copy}</p>
                    {link && link.cached_url && (
                      <div className="cause-card__bottom">
                        <a
                          className="thm-btn "
                          href={link.cached_url}
                          target="_blank"
                          style={{ backgroundColor: '#958FA3' }}
                        >
                          {link.linktype === 'asset'
                            ? 'Download'
                            : 'Visit Website'}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </a>
            </div>
          )
        )}
      </div>
      <SupportPagePagination />
    </Container>
  </section>
);

export default CausesPage;
