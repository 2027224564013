import React from 'react';
import { Container } from 'react-bootstrap';

import Step from './step';

const Steps = ({ content }) => (
  <Container>
    <section className="steps">
      {content.steps.map((step, index) => (
        <Step index={index + 1} copy={step.copy} />
      ))}
    </section>
  </Container>
);

export default Steps;
