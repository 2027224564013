import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SbImageHandler from '../../helpers/SbImageHandler';

const AboutOne = ({ content }) => (
  <section className="about-one pt-120 pb-40">
    <Container>
      <div className="team-about__top mt-60">
        <Row>
          <Col md={12} lg={4}>
            <div className="block-title">
              <p>{content.subtitle}</p>
              <h3>{content.title}</h3>
            </div>
          </Col>
          <Col md={12} lg={4}>
            <p className="team-about__top-text">{content.copy_a}</p>
          </Col>
          <Col md={12} lg={4}>
            <p className="team-about__top-text">{content.copy_b}</p>
          </Col>
        </Row>
      </div>
    </Container>

    <Container>
      <Row>
        <Col lg={6}>
          {content.image_a && content.image_a.filename && (
            <img
              src={SbImageHandler(content.image_a.filename)}
              alt=""
              className="img-fluid"
            />
          )}
        </Col>
        <Col lg={6}>
          {content.image_b && content.image_b.filename && (
            <img
              src={SbImageHandler(content.image_b.filename)}
              alt=""
              className="img-fluid"
            />
          )}
        </Col>
      </Row>
    </Container>
  </section>
);

export default AboutOne;
